import * as Sentry from '@sentry/react';

import { sendEvent } from '@nubank/www-latam-commons/services/analytics';
import isEmpty from '@nubank/www-latam-commons/utils/object/isEmpty';

import getClient from './getClient';
import getPersona from './getPersona';

export default async function sendPersonaToGTM(client = getClient()) {
  try {
    const profile = await getPersona(client);

    if (!isEmpty(profile)) {
      sendEvent('NAVEGG_PROFILE_REGISTER', { naveggProfile: profile });
    }
  } catch (error) {
    Sentry.captureException(error);
  }
}
