import { sendEvent } from '@nubank/www-latam-commons/services/analytics';

export const trackWebsiteResearchAnnouncementOpened = () => {
  sendEvent('NAVIGATION_UX_SURVEY_ANNOUNCEMENT_OPEN');
};

export const trackWebsiteResearchAnnouncementClosed = () => {
  sendEvent('NAVIGATION_UX_SURVEY_ANNOUNCEMENT_CLOSE');
};

export const trackWebsiteResearchAnnouncementClick = () => {
  sendEvent('NAVIGATION_UX_SURVEY_ANNOUNCEMENT_CLICK');
};
