import {
  trackWebsiteResearchAnnouncementOpened,
  trackWebsiteResearchAnnouncementClick,
  trackWebsiteResearchAnnouncementClosed,
} from 'tracking/websiteResearch/announcement';

const DISPLAY_PERCENTUAL = 0.16;
export const canBeDisplayed = Math.random() < DISPLAY_PERCENTUAL;

const survey = {
  announcementBarId: 'navigation_ux_survey',
  trackOpenedFn: trackWebsiteResearchAnnouncementOpened,
  trackClickFn: trackWebsiteResearchAnnouncementClick,
  trackClosedFn: trackWebsiteResearchAnnouncementClosed,
  announcementBarDelay: 20000,
};

export default survey;
